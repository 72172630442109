<template>
  <div>
    <v-card flat class="mt-6 pa-4">
      <!-- <div class="mb-4">
        <v-card flat class="d-flex align-center flex-between">
          <input
            :placeholder="$t('用户名')"
            v-model="param.username"
          />
          <v-btn depressed class="" width="60" color="primary" @click="getData">
            <v-icon color="#fff" size="28">mdi-magnify</v-icon>
          </v-btn>
        </v-card>
      </div> -->
      <v-data-table
        :headers="headers"
        :items="datalist"
        :no-data-text="$t('暂无数据')"
        class="elevation-0"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.amount="{ item }">
          <span class="textred"> ¥{{ item.amount }}</span>
        </template>
        <template v-slot:item.create_time="{ item }">
          <span> {{ item.create_time | formFilter }}</span>
        </template>
      </v-data-table>
      <div
        v-if="pagearr.pagetotal > 0"
        class="text-center d-flex mt-4"
        align="center"
      >
        <v-row align="center" class="justify-center">
          <span>共{{pagearr.pagetotal}}页</span>

          <v-pagination
            v-model="pagearr.page"
            :length="pagearr.pagetotal"
            total-visible="5"
            @input="getpagedata(1)"
          />
          <span class="mr-4 hidden-xs-only">{{ $t("条") }}</span>
          <span class="ml-6 hidden-xs-only">
            {{ $t("跳转至") }}
            <input
              v-model="pagearr.page"
              autocomplete="off"
              style="width: 80px"
              class="border in-border pl-2 pt-1 ml-2 mr-2 pb-1"
              type="number"
              @input="numpagedata"
              @blur="getpagedata(1)"
            />
            {{ $t("页") }}
          </span>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      pagearr: {
        pageList: [15, 30, 50, 100, 500, 1000, 2000],
        page: 1,
        pagenum: 15,
        pagetotal: 50,
        total: 0,
      },
      headers: [
        {
          text: this.$t("邀请时间"),
          align: "start",
          sortable: true,
          class: "title-bg",
          value: "create_time",
        },
        { text: this.$t("用户"), value: "nick_name", class: "title-bg" },
        { text: this.$t("获得收益"), value: "amount", class: "title-bg" },
      ],
      datalist: [],
      param: {
        page: 1,
        page_size: 10,
        username: "",
        time: "",
      },
      info: {
        allamount: 0,
        num: 0,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.user.get_below_detail(this.param).then((res) => {
        if (res.status == "success") {
          this.datalist = res.data;
          this.pagearr.pagetotal = Math.ceil(
            parseInt(res.total) / this.pagearr.pagenum
          );
        } else {
          this.datalist = [];
          this.pagearr.pagetotal = 0;
        }
      });
    },
  },
};
</script>
<style>
input {
  outline: none;
  height: 36px;
  background: #f2f2f2;
  padding: 0 3px;
  /* border: 1px solid #666; */
}
</style>
<style scoped>
/deep/ .v-pagination  .v-pagination__item{
  box-shadow: none;
  background: #F2F2F2;
}
/deep/ .v-pagination__navigation{
  box-shadow: none;
  background: #F2F2F2 !important;
}
/deep/ .v-text-field.v-text-field--enclosed .v-text-field__details{
  display: none;
}
/deep/ .theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot{
  
}

</style>